<script setup>
import Utility, { handleApiResponse } from '~/server/utils/utils';

const route = useRoute();
const { t } = useI18n();
let head = ref({});
head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});
const title = computed(() =>
  t(route.meta.title ?? 'TBD', t('TourScanner - Find your ideal travel activity at the best price')),
);
const defaultOgImage = 'https://tourscanner.com/blog/wp-content/uploads/og_image.png';

const urlScomposed = Utility.scomposeURL(route.path);
// If any of the 4 parameters are present, we call the API to get the head links
if (urlScomposed.result.s || urlScomposed.result.c || urlScomposed.result.i || urlScomposed.result.t) {
  // Call the API
  const { data, error } = await handleApiResponse(`/api/getHrefsLinks`, {
    params: {
      path: route.path,
    },
  });

  // Ensure the ids match those set by useLocaleHead
  const apiLinks = data.value.map((link) => ({
    ...link,
    // The id should match the one set by useLocaleHead
    id: link.id, // Make sure this id matches
  }));

  // Call useHead after useLocaleHead to replace the links
  head.value.link = apiLinks;
}
</script>

<template>
  <div class="min-h-screen pt-2 md:pt-5 mx-auto max-w-screen-xl px-0 md:px-4 pb-20">
    <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
      <Head>
        <Title>{{ title }}</Title>
        <template v-for="link in head.link" :key="link.id">
          <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
        </template>
        <template v-for="meta in head.meta" :key="meta.id">
          <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
        </template>
        <Meta name="theme-color" content="#33B1E3" />
        <Meta property="og:image" :content="route.meta.ogImage || defaultOgImage" />
      </Head>
      <Body>
        <slot />
      </Body>
    </Html>
  </div>
</template>
